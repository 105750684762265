import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import SubNavigationListItem from "components/SubNavigation/List/Item"

const SubNavigationList = React.forwardRef((props, ref) => (
  <ul
    ref={ref}
    className={classNames(
      "flex items-center overflow-x-scroll md:justify-center",
      props.className,
    )}>
    {props.items.map((item) => (
      <SubNavigationListItem
        className="mx-4 lg:mx-6"
        title={item.title}
        key={item.id}
        id={item.id}
      />
    ))}
  </ul>
))

SubNavigationList.displayName = "SubNavigationList"
SubNavigationList.propTypes = {
  className: PropTypes.string,

  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
}

export default SubNavigationList
