import React, { useRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import config from "config"

import useSubNavigationScroll from "components/SubNavigation/useScroll"

import SubNavigationList from "components/SubNavigation/List"
import SubNavigationScrollRightButton from "components/SubNavigation/ScrollRightButton"

const SubNavigation = (props) => {
  const scrollableElement = useRef(null)
  const { scrollRight } = useSubNavigationScroll(scrollableElement.current)

  return (
    <div
      className={classNames(
        "h-12 md:h-14 flex relative border-b border-element-divider-light bg-base-background-light fs-base-interface-regular",
        props.className,
      )}>
      <SubNavigationList
        className="w-full h-full"
        ref={scrollableElement}
        items={config.navigations.subNavigation}
      />

      <SubNavigationScrollRightButton
        onClick={scrollRight}
        className="right-0 w-12 h-full bg-base-surface-light md:hidden"
      />
    </div>
  )
}

SubNavigation.propTypes = {
  className: PropTypes.string,
}

export default SubNavigation
