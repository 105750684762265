/**
 * 運営側の作ったPlayList、ユーザーが作ったPlayListはIDが連番
 * URLにIDを記載したくないのでHashidを使って難読化している
 *
 * encode の順番は下記のとおり
 *   playlist_id, dummy_id, library_id, creator_id
 *
 * -------------
 * playlist_id : 運営側が作成したplaylistのID (playlists.playlist_id)
 * dummy       : 1229
 * library_id  : ユーザーが作成したlibrary_id (libraries.library_id)
 * creator_id  : 作成したユーザーID (libraries.creator_id / ios_users.id)
 *
 * SALT : NobodySurf-Playlist
 */
import Hashids from "hashids/cjs"
const hashids = new Hashids("NobodySurf-Playlist")
const dummy_id = 1229

export function toPlaylistHash(playlist_id) {
  return hashids.encode(playlist_id, dummy_id, 0, 0)
}
export function toLibraryHash(library_id, creator_id) {
  return hashids.encode(0, dummy_id, library_id, creator_id)
}
export function decode(hash) {
  var [playlist_id, dummy_id, library_id, creator_id] = hashids.decode(hash)
  return {
    playlist_id,
    library_id,
    creator_id,
    dummy_id,
  }
}
