import smoothscroll from "smoothscroll-polyfill"

const useSubNavigationScroll = (scrollableElement) => {
  const scrollRight = () => {
    smoothscroll.polyfill()

    scrollableElement.scrollBy({
      left: 200,
      behavior: "smooth",
    })
  }

  return { scrollRight }
}

export default useSubNavigationScroll
