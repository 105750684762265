import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

const SubNavigationScrollRightButton = (props) => (
  <button
    className={classNames(
      "fs-base-interface-bold flex items-center justify-center",
      props.className,
    )}
    onClick={props.onClick}>
    <span className="tracking-tighter">▶︎</span>
  </button>
)

SubNavigationScrollRightButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

export default SubNavigationScrollRightButton
