import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Link from "next/link"
import { withRouter } from "next/router"
import { toPlaylistHash } from "lib/playlistHash"
import { event } from "lib/gtag"

const handleItemClick = (title) => {
  event({ action: "SubNavigation", category: "Click", label: title })
}

const SubNavigationListItem = withRouter(({ router, ...props }) => {
  const hash = toPlaylistHash(props.id)
  const url = `/playlist/${hash}`
  const active = url === router.asPath.replace(/\/$/, "") ? true : false
  return (
    <li className={classNames("flex whitespace-nowrap", props.className)}>
      <Link href={url}>
        <a
          className={classNames(
            "whitespace-nowrap",
            active
              ? "text-element-high-emphasis-light"
              : "text-element-medium-emphasis-light",
          )}
          onClick={() => handleItemClick(props.title)}>
          {props.title}
        </a>
      </Link>
    </li>
  )
})

SubNavigationListItem.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export default SubNavigationListItem
